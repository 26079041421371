<div class="navbar-background" [class.test]="isCollapsed"></div>
<nav class="navbar navbar-expand-xl navbar-transparent py-xl-3">
    <div class="container">
        <a routerLink="">
            <img [src]="globalContent?.logo.url" [alt]="globalContent?.logo.alt" [title]="globalContent?.logo.alt" class="brand" />
        </a>
        <button class="navbar-toggler" type="button" (click)="collapse()">
            <img [src]="isCollapsed ? '/assets/images/close-white.svg' : '/assets/images/menu.svg'" alt="Menu Icon" />
        </button>
        <div class="collapse navbar-collapse" id="navbarNav" [class.show]="isCollapsed">
            <div class="w-100 d-xl-flex justify-content-xl-between">
                <!-- Menu items -->
                <ul class="navbar-nav d-flex align-items-center">
                    @for (menuItem of globalContent?.navigation_menu_items; track $index) {
                        <li class="nav-item mt-4 mt-xl-0 ms-xl-5">
                            <app-link ngSkipHydration [link]="menuItem.link" routerLinkActive="active-link" [cssClass]="'nav-link'" (click)="closeMenu()">
                                {{ menuItem.title }}
                            </app-link>
                        </li>
                    }
                </ul>
                <!-- Action items -->
                <div class="mt-4 mt-xl-0 d-flex justify-content-center">
                    @for (menuItem of globalContent?.navigation_action_items; track $index) {
                        <app-link ngSkipHydration [link]="menuItem.link" [cssClass]="'btn ' + menuItem.style + ' ms-4'" (click)="closeMenu()">
                            <div class="d-flex align-items-center">
                                <img [src]="menuItem.icon.url" [alt]="menuItem.icon.alt" class="icon-tiny pe-2" />
                                {{ menuItem.title }}
                            </div>
                        </app-link>
                    }
                </div>
            </div>
        </div>
    </div>
</nav>
