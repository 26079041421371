import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { GlobalContent } from '../../../services/global-content.service';
import { LinkComponent } from '../link/link.component';

@Component({
    selector: 'app-navigation',
    standalone: true,
    imports: [CommonModule, LinkComponent, RouterLink],
    templateUrl: './navigation.component.html',
    styleUrl: './navigation.component.scss'
})
export class NavigationComponent {
    @Input({ required: true }) globalContent?: GlobalContent;

    isCollapsed = false;

    collapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    closeMenu(): void {
        this.isCollapsed = false;
    }
}
