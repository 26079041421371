import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GlobalContent } from '../../../services/global-content.service';
import { LinkComponent } from '../link/link.component';

@Component({
    selector: 'app-action-button',
    standalone: true,
    imports: [CommonModule, RouterModule, LinkComponent],
    templateUrl: './action-button.component.html',
    styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {
    @Input() globalContent?: GlobalContent;

    ngOnInit(): void {}
}
