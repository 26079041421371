import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import AOS from 'aos';
import { ActionButtonComponent } from './components/shared/action-button/action-button.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { NavigationComponent } from './components/shared/navigation/navigation.component';
import { GlobalContent, GlobalContentService } from './services/global-content.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, FooterComponent, NavigationComponent, ActionButtonComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
    globalContent?: GlobalContent;
    private readonly globalContentService = inject(GlobalContentService);

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

    ngOnInit() {
        this.globalContentService.getGlobalContent().subscribe((globalContent) => {
            if (globalContent) {
                this.globalContent = globalContent;
            }

            if (isPlatformBrowser(this.platformId)) {
                AOS.init();
            }
        });
    }
}
