import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { HTMLRichTextMapSerializer } from '@prismicio/client';
import { GlobalContent } from '../../../services/global-content.service';
import { LinkComponent } from '../link/link.component';
import { RenderHtmlPipe } from '../prismic/render-html/render-html.pipe';
import { serializeHyperlink, titleMSerializer } from '../prismic/serializer';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [CommonModule, LinkComponent, RenderHtmlPipe],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent {
    @Input({ required: true }) globalContent!: GlobalContent;

    year = new Date().getFullYear();

    protected readonly titleMSerializer = titleMSerializer;

    phoneSerializer: HTMLRichTextMapSerializer = {
        paragraph: ({ children }) => `<p class="color-white">${children}</p>`,
        hyperlink: ({ children, node }) => serializeHyperlink(children, node, '', true)
    };
}
