<footer class="background-red mt-5 mt-lg-6">
    <div class="py-5">
        <div class="container">
            <div class="row mt-5 d-flex justify-content-between">
                <div class="col-lg-6 pe-lg-6 mb-5 mb-lg-0 mt-0">
                    <div [innerHTML]="globalContent?.footer_title | renderHtml: titleMSerializer"></div>
                    <div [innerHTML]="globalContent?.footer_description | renderHtml" class="my-5"></div>
                    <app-link ngSkipHydration [link]="globalContent?.footer_button_link" cssClass="button button-white">{{ globalContent?.footer_button_text }}</app-link>
                </div>
                <div class="col-lg-3 mb-5">
                    <h3 class="text-size-m">{{ globalContent?.footer_links_title }}</h3>
                    <ul class="mt-5">
                        <li *ngFor="let link of globalContent?.footer_links">
                            <app-link ngSkipHydration [link]="link.link">
                                {{ link.title }}
                            </app-link>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3 mb-5">
                    <p class="text-size-m color-white">Kontakt</p>
                    <div class="my-5">
                        <div [innerHTML]="globalContent?.footer_contact_adress | renderHtml"></div>
                        <div [innerHTML]="globalContent?.footer_contact_phone | renderHtml: phoneSerializer"></div>
                        <a [href]="'mailto:' + globalContent?.footer_contact_email">{{ globalContent?.footer_contact_email }}</a>
                    </div>
                    <ul>
                        <li *ngFor="let link of globalContent?.footer_links_legal">
                            <app-link ngSkipHydration [link]="link.link">
                                {{ link.title }}
                            </app-link>
                        </li>
                    </ul>
                </div>
            </div>
            <hr />
            <div class="row justify-content-between mt-5">
                <div class="col-7">
                    <p class="d-flex">© {{ year }} www.kurhessen-therme.de</p>
                </div>
                <div class="col-5 text-end">
                    <div>
                        @for (social of globalContent?.footer_social_links; track social; let last = $last) {
                            <app-link ngSkipHydration [link]="social?.link">
                                <img class="icon-small" [class.me-3]="!last" [src]="social?.icon?.url" [alt]="social?.icon?.alt" />
                            </app-link>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
